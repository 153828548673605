.dock-container {
    text-align: center;
    height: 30px;
    width: 100%;
    background-color: #64BCAD;
    color: #1C1A32;
    border-top:1px solid #333333;
    position: relative;
    display: flex;
}

.App-clock{
  line-height: 30px;
  text-align: right;
  width: 50%;
  padding-right: 20px;
}



.dock-container a{
  
  margin-left: 20px;
  text-align: left;
  display:inline-block;
  padding:0 5px;
  font-weight: bold;
  line-height: 30px;


}

.dock-container a:hover {
  
  opacity: 0.9;
}

.dock-container div{
  width: 50%;
  text-align: left;
}



@media only screen and (min-width: 800px) {

  .App-clock{
    padding-right: 70px;
  }

  .dock-container a{
    margin-left: 70px;
  }
}