
.clear {
	clear: both;
}

 .band-display-container .horizontal-slider .thumb:focus {outline:0;}

.electricalInterface-container {
	display: flex;
	flex-direction: row;
	flex:1;
	/*align-items: center;*/
	overflow-x:auto;
}

.electricalInterface-content {

	display: flex;
	flex-direction: row;
	min-width: 948px;
    /*min-height: 800px;*/
    height: 800px;
    border: solid 1px #979797;
    margin: 0 auto;
    background: #6D6C6C;
    border-radius: 20px;
    -moz-box-shadow:    inset 0 0 15px #696969;
   -webkit-box-shadow: inset 0 0 15px #696969;
   box-shadow:         inset 0 0 15px #696969;
   margin-top: 3vh;
}



.electricalInterface-container img {  
   user-drag: none;  
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}


.electricalInterface-container  button {
	background-color: #D8D8D8;
	text-transform: uppercase;
	padding:22px 30px;
	border-radius: 20px;
	border:2px solid #979797;
	font-size: 16px;
	-moz-box-shadow:    inset 0 0 15px #979797;
   -webkit-box-shadow: inset 0 0 15px #979797;
   box-shadow:         inset 0 0 15px #979797;
}

.electricalInterface-container  button:hover {
	-moz-box-shadow:    inset 0 0 15px #696969;
   -webkit-box-shadow: inset 0 0 15px #696969;
   box-shadow:         inset 0 0 15px #696969;
}


.filamentControl-container {
	flex-basis: 47%;
}

.filamentControl-container .topPart {
	display: flex;
	align-items: center;
	margin-top: 11px;
	margin-left: 40px;
}

.filamentControl-container .topPart .light-container {
	display: flex;
	margin-right: 36px;
}

.filamentControl-container .topPart .light-container  div:first-child {
	margin-right: 12px;
}
.light-content {
	position: relative;
}

.light-content p {
	position: absolute;
	top:0;
	font-size: 14px;
	color:#1C1B1B;
}


.light-content.large {
	width: 100px;
	height: 100px;
	background-size: 47px 47px;
	background-position: 26.5px;
	background-repeat: no-repeat;
}

.light-content.large p {
	top: 76px;
    left: 39px;
    font-size: 16px;

}

.light-content.medium-large {
	width: 45px;
	height: 45px;
	background-size: 21px 21px;
	background-position: 12px;
	background-repeat: no-repeat;
}


.light-content.medium {
	width: 30px;
	height: 30px;
	background-size: 14px 14px;
	background-position: 8px;
	background-repeat: no-repeat;
}

.light-content.medium:first-child p{
	top:-20px;
}

.light-content.medium p{
	top:20px;
	left: 5px;
}


.filament-container .light-content.medium p{
	top: 24px;
}



.light-content.small {
	width: 21px;
	height: 21px;
	background-size: 10px 10px;
	background-position: 5.5px;
	background-repeat: no-repeat;
}

.light-content.small:first-child p{
	top:-12px;
}

.light-content.small p{
	top:18px;
	left: 2px;
	font-size: 12px;
}


.light-content.red-light {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/red-light.svg);
}

.light-content.green-light {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/green-light.svg);
}

.band-container .topPart .medium-large p {
	display: none;
}

.filament-container {
	display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.filament-container .light-container {
	margin-right: 5px;
	margin-bottom: 30px;
}

.filament-container .light-container div:first-child {
	margin-bottom: 16px;
}

.knob-container {
	display: inline-block;
}

.knob-container h2, .meter-container h2,.slider-commit-container h2   {
	text-transform: uppercase;
	font-size: 12px;
	text-align: center;
	margin-top: -10px;
}

.meter-container h2{
	font-size: 16px;
	margin-top: 10px;

}

.knob-container .base {
	width: 144px;
	height: 140px;
	background-size: 144px 125px;
	background-position: top;
	background-repeat: no-repeat;
	position: relative;
}

.filamentVoltage-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/filament-voltage-knob-base.svg);
	
}

.plateCurrent-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/plate-current-knob-base.svg);
	
}

.RFLineCurrent-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/r-f-line-current-knob-base.svg);
}

.plateVoltage-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/plate-voltage-knob-base.svg);
}



.knob {
	width: 47px;
	height: 105px;
	position: absolute;
    top: 24px;
    left: 47px;
}

.meter-container {
	margin-right: 37px;
}

.meter-container .base{
	width: 198px;
	height: 109px;
	background-size: 198px 109px;
	background-position: top;
	background-repeat: no-repeat;
	position: relative;
	margin-top: 8px;
}

.meterVoltage-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/filament_voltage_meter.svg);
	
}


.meterPlateCurrent-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/plate_current_meter.svg);
}

.meterRFLineCurrent-base{
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/rf_line_current_meter.svg);
}

.meterPlateVoltage-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/plate_voltage_meter.svg);
}


.dial {
	top: 15px;
    width: 8px;
    height: 67px;
    position: absolute;
    transform-origin: bottom center;
    left: 95px;
}

.band-container {
	flex-basis: 53%;
	padding-right: 25px;
}

.band-container button {
    padding: 5px 20px;
    border-radius: 20px;
    border: 2px solid #979797;
    font-size: 16px;
    box-shadow: inset 0 0 15px #979797;
    margin: 0 20px;
}


.band-container .topPart {
	display: flex;
	align-items: center;
	margin-top: 60px;
	float: right;
	margin-bottom: 11px;
	
}


.tuner-container {
	display: flex;
	align-items: center;
}

.tuner-container .light-container {
	margin-right: 5px;
	margin-left: 5px;
}

.tuner-container .knob-container h2{
	margin-top: 5px;
}

.tuner-knob {
	width: 26px;
	height: 26px;
	position: absolute;
    top: 39px;
    left: 43px;
}



.tuner-container .tuner-base {
	width: 114px;
	height: 80px;
	background-size: 114px 80px;
	background-position: top;
	background-repeat: no-repeat;
	position: relative;
}

.volume-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/volume-knob-base.svg);
}

.paVolt-base {
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/p-a-volt-tuner-base.svg);
}

.band-display {
	width: 449px;
	height: 230px;
	background-size: 449px 230px;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	background-image: url(https://cdn.huntakiller.com/blair-witch/s2/electricalinterface/wave-length-display.svg);
	margin-right: 0;
    margin-left: auto;
    margin-bottom: 30px;
    position: relative;
}

.band-display > img {
	width: 449px;
	height: 230px;
	position: absolute;
	top:0;
	left: 0;
}

.slider-container {
	margin-bottom: 14px;
	display: flex;
	margin-left: 8px;
	
}

.slider-container > div {
	margin-right: 3px;
}

.band-display-container .horizontal-slider  {
	height: 40px;
	width: 323px;
	margin-right: 2px;
    margin-left: auto;
}

.band-display-container .horizontal-slider .track {
	top:16px;
	height: 8px;
	background-color: #2A2A2A;
	border-radius: 10px;

}

.band-display-container .horizontal-slider .thumb {
	border: 2px solid #A14646;
	width: 16px;
	height: 40px;
	background: #AC533E;
	 -moz-box-shadow:    inset 0 0 5px #2A2A2A;
   -webkit-box-shadow: inset 0 0 5px #2A2A2A;
   box-shadow:         inset 0 0 5px #2A2A2A;
   cursor: pointer;
}

.band-number {
	width: 323px;
	margin-right: 0;
    margin-left: auto;
}

.slider-commit-container{
	display: flex;
	margin-left: 27.375px;
	align-items: center;
}

.commit-button {
	margin-left: 15px;
	width: 74px;
	cursor: pointer;
}

.commit-button:hover {
	opacity: 0.9;
}

.slider-commit-container h2  {
	font-size: 16px;
	margin-top: 10px;
	margin-left: 15px;
}

.band-container .bottomPart, .filamentControl-container  .bottomPart {
	margin-left: 27.375px;
    display: flex;
    align-items: center;
    margin-top: 21px;
}

.band-container .bottomPart .tuner-container, .filamentControl-container  .bottomPart  .tuner-container {
	flex:1;
}

.logo-tower {
	width: 121px;
}

.filamentControl-container  .bottomPart {
    	display: none;
    }
@media only screen and (max-width: 799px) {
	.band-container button , .electricalInterface-container button{
		font-size: 12px;
	}
	.band-container button {
		margin:0;
	}
	.electricalInterface-container button {
		padding: 10px 25px;
	}
	.knob-container h2, .meter-container h2, .slider-commit-container h2 {
		font-size: 10px;
	}
	.electricalInterface-container {
		display: block;

	}
	.electricalInterface-content {
		width: 100%;
		min-width: fit-content;
		flex-direction: column-reverse;
		height: auto;
		margin-top: 0;
		border-radius: 0;
    	
    }

    .band-container {
    	padding : 0 20px;
    }

    .band-container .topPart {
    	float: none;
    	justify-content: space-evenly;
    	margin-top: 14px;
    }

    .filamentControl-container .topPart {
    	margin-left: 0;
    	justify-content: space-evenly;
    }

    .filamentControl-container .topPart .light-container, .meter-container {
    	margin-right: 0;
    }

    .filament-container {
    	margin-left: 0;
    	justify-content: space-evenly;
    }

    .band-display-container {
    	text-align: center;
    }

    .band-display {
    	margin-left: auto;
    	margin-right: auto;
    	width: 272px;
	    height: 139px;
	    background-size: 272px 139px;

    }

    .band-display > img {
		width: 272px;
	    height: 139px;
		position: absolute;
		top:0;
		left: 0;
	}

    .slider-commit-container {
    	margin-left: auto;
	    margin-right: auto;
	    display: inline-flex;
    }

    .band-container .bottomPart {
    	display: none;
    }

    .light-content p {
    	font-size: 12px;
    }


    .light-content.medium {
	    width: 24px;
	    height: 24px;
	    background-size: 11px 11px;
	    background-position: 6.5px;
    }

    .light-content.medium p {
    	left: 4px;
    }

    .filament-container .light-content.medium p {
	    top: 20px;
	    font-size: 10px;
	}


    .light-content.medium-large {
	    width: 36px;
	    height: 36px;
	    background-size: 17px 17px;
	    background-position: 9.5px;
	}

	.light-content.small {
	    width: 15px;
	    height: 15px;
	    background-size: 7px 7px;
	    background-position: 4px;
	}

	.light-content.small:first-child p {
	    top: -13px;
	}
	.light-content.small p {
	    top: 12px;
	    left: 0px;
	    font-size: 10px;
	}

	.light-content.large {
	    width: 60px;
	    height: 60px;
	    background-size: 28px 28px;
	    background-position: 16px;
	    background-repeat: no-repeat;
	}

	.light-content.large p {
		top: 45px;
	    left: 22px;
	    font-size: 14px;

	}

	.tuner-container .tuner-base {
		width: 91px;
	    height: 64px;
	    background-size: 91px 64px;
	}
	.tuner-knob {
		width: 21px;
	    height: 21px;
	    top: 31px;
	    left: 35px;
	    touch-action: manipulation;
	}

	.band-display-container .horizontal-slider {
		width: 195px;
		height: 24px;
	}

	.band-number {
		margin: 0 auto;
	    width: 189px;
	    margin-top: 5px;
	}

	.commit-button {
		width: 46px;
	}

	.commit-button, .slider-commit-container h2 {
		margin-left: 5px;
	}

	.band-display-container .horizontal-slider .thumb {
		width: 11px;
		height: 24px;
		top: 8px;
	}

	.filament-container .light-container {
		margin-right: 0;
    	margin-bottom: 10px;
	}

	.filament-container .light-container div:first-child {
		margin-bottom: 3px;
	}
	.meter-container h2 {
	 	font-size: 8px;
	 }

	.meter-container .base {
	    width: 123px;
	    height: 67px;
	    background-size: 123px 67px;
	    margin-top: 12px;
	    touch-action: manipulation;
	}

	.dial {
	    top: 10px;
	    width: 5px;
	    height: 40px;
	    left: 59px;
	}

	.knob-container .base {
		width: 91px;
	    height: 88px;
	    background-size: 91px 88px;
	    touch-action: manipulation;
	}

	.knob {
	    width: 30px;
	    height: 66px;
	    top: 21px;
    	left: 30px;
    	touch-action: manipulation;
	}
	
	.knob-container h2 {
		margin-top: 0
	}

	.filamentControl-container .bottomPart {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-evenly;
		margin-left: 0;
		margin-bottom: 30px;
	}
	.filamentControl-container .bottomPart .tuner-container {
	    flex: inherit;
	}
	.logo-tower {
		width: 77px;
	}
	.slider-commit-container, .slider-commit-container > div {
		-webkit-touch-callout: none;               
		-webkit-text-size-adjust: none;            
		-webkit-user-select: none;    
	}
}