@font-face {
  font-family: 'CCMonsterMash';
  src: url('https://cdn.huntakiller.com/blair-witch/s2/fonts/CCMonsterMash.eot'); /* IE9 Compat Modes */
  src: url('https://cdn.huntakiller.com/blair-witch/s2/fonts/CCMonsterMash.woff') format('woff'), /* Modern Browsers */
       url('https://cdn.huntakiller.com/blair-witch/s2/fonts/CCMonsterMash.ttf')  format('truetype'); /* Safari, Android, iOS */
}

#body {
    
    overflow-y: hidden;
    overflow-x: hidden;

}
#root, .app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}


body{


}

a, img, h1, p, h2, button {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}


.mobile {
    display: block;
}

.desktop {
    display: none;
}


.flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}


.relative {
    position: relative;
}


.icon-wrapper{
    position: relative;
    margin:0 auto;
    margin-bottom: 50px;
    width: 70px;
    height: 99px;
    cursor: pointer;
    
}

.icon-wrapper .exclamation-bubble{
    position: absolute;
    right: 13px;
    top: 9px;
    width: 25px;
}


.icon-wrapper.selected .icon-image{
    border: 1px solid #dae1e7;
}

.icon-wrapper.selected .icon-name span {
    white-space: pre-wrap;
    background-color: #2779bd;
    color: #fff;
}

.just4Fun-dark-blue-color {
    color: #2A3474
}


.icon-name {
    color: #22292f;
    font-size: .875rem;
    text-align: center;
    pointer-events: none;
    margin-top: .25rem;
}

.icon-name span {
    padding-left: .25rem;
    padding-right: .25rem;
}

.icon-wrapper .icon-image{
    border: 1px solid transparent;
    /*width: 64px;
    height: 64px;*/

    background-size:100%;
    background-repeat: no-repeat;
    pointer-events: none;
    
}

.z-10 {
    z-index: 10;
}

#email-icon {
    bottom: 50px;
    right: 11px;
}


.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}


.seasonDesktop {
    background-color: #E3E3E3;
}

.login-box{
    background-color: #171623;
    width: 250px;
    max-width: 90%;
    position: relative;
}

.login-box.password-final-video {
    background-color: #CD442B;

}

.login-box.password-final-video h2 {
    text-align: center;
    color: #1D1934;
    font-weight: bold;
}

.login-box.password-final-video .error {
    color: #1D1934;
}

.login-box.password-final-video .bg-yellow {
    background-color: #1D1934;
    color: #fff;
}

.login-box.forgot-password-box{
    width: 500px;
}

.login-box  .close-icon-modal {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    cursor: pointer;
}
.login-box h1, .login-box a{
    color:#fff;
}

.login-box.forgot-password-box h1{
    color:#D8D5AE;
    font-weight: bold;
    text-transform: uppercase;
}

.login-box a{
    text-decoration: underline;
}
.login-box img {
    max-width: 70%;
}

.error {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color:#E04403;
}


.bg-grey {
    background-color: #DEDEDE;
}


.bg-grey:hover {
    background-color: #DEDEEE;
}


.bg-yellow {
    background-color: #3BBFAD;
    color: #1D1934;
}

.bg-yellow:hover {
    opacity: 0.9;
}

.txt-yellow {
    color: #D8D5AE;
}


.modal {
    position: absolute;
    width:100%;
    /*height: 100vh;*/
    /*background-color: #fff;*/
    background-color: #CBCACE;
    left:0;
    top: 0;
    max-width: 100%;
    border: 1px solid #B3B3B3;
    /*min-height: 100vh;*/
}

.overlaypw .modal {
    background-color: #fff;
}

.modalDiv{
    width: 100%;
    height: 100%;
}

.modalcontainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.modalcontainer .header, .blogPostFromReconTeamContainer .header{
    background-color: #3BBFAD;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    position: relative;
    color:#1D1934;
    text-transform: uppercase;
}

.modalcontainer .close, .blogPostFromReconTeamContainer .close{
    height: 50px;
    width: 50px;
    position: absolute;
    background-image: url('https://cdn.huntakiller.com/blair-witch/s2/close-button-blue.svg');
    background-repeat: no-repeat;

    background-position: center;
    background-size: 25px;
    right: 0px;
    top: 0px;
}

.ScrollbarsCustom{
    width: 100%;
    height: 100%;
    display: block;
    flex: 1 1;
}

.imageViewer{
    position: relative;
    width: 100%;
    height: calc(100% - 90px);
}


.bottom-imageViewer-controllers{
        
        position: fixed;
        bottom: 00px;
        left: 0;
        width: calc( 100%);
        height: 40px;
        background-color: #B3B3B3;
        
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        
    }


    .bottom-imageViewer-controllers button,  .bottom-imageViewer-controllers a{
        width: 100px;
        height: 25px;
        margin-top: 7.5px;
        background-color: #FEFEFE;
        border-radius: 10px;
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;
        display: inline-block;
        -moz-box-shadow:    inset 0 0 2px #333;
        -webkit-box-shadow: inset 0 0 2px #333;
        box-shadow:         inset 0 0 2px #333;
     }

     .bottom-imageViewer-controllers button{
        display: none;
     }

     .bottom-imageViewer-controllers a{
        float: right;
        font-size: 0.6rem;
        line-height: 25px;

     }


    .bottom-imageViewer-controllers button:focus{
        border: none;
        outline: none;
    }

.content-file{
    max-width: 100%;
    max-height: 100%;
}


.rtfdoc{
    padding: 20px;
    font-size: 12px;
    width:calc(100% );


}

object{
    max-width: 100%;
}

.rtfdoc h2, .rtfdoc p, .rtfdoc object{
    margin-bottom: 15px;
}

.rtfdoc h2{
    font-weight: bold;
    font-size: 14px;
}

.rtfdoc ul {
    list-style: disc;
    padding-left: 15px;
    margin-bottom: 15px;
}

.rtfdoc ol {
    padding-left: 30px;
    margin-bottom: 15px;
    list-style: decimal;
}

.rtfdoc ul li, .rtfdoc ol li{
    margin-top: 10px;
    margin-bottom: 10px;
}

.rtfdoc a{
    color:blue;
    text-decoration: underline;
}

.rtfdoc table{
    min-width: 120%;
    width: 100%;
}
.rtfdoc table th, .rtfdoc table td {
    padding:10px;
}
.rtfdoc table, .rtfdoc th, .rtfdoc td {
  border: 1px solid black;
}

.rtfdoc table tr:nth-child(even) {
  background-color: #eee;
}
.rtfdoc table tr:nth-child(odd) {
  background-color: #fff;
}

.strikethrough{
    text-decoration: line-through;
}

.just4FunSite, .inbox {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;

}



.just4FunSite .header {
    background-color: #D9DD50;
    text-align: center;
    padding:10px 0;
}

.just4FunSite .header .logo {
    cursor: pointer;
    width: 250px;
}




.just4FunSite  .footer {
    background-color: #D9DD50;
    width:100%;
    height: 50px;
    align-items: flex-end;
    line-height: 50px;
}

.just4FunSite-container {
    padding: 0rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
}



.just4FunSite .header .close-icon,  .cheeseburgerMenu .close-icon{
    width: 45px;
    position: absolute;
    right: 0rem;
    top:0rem;
    cursor: pointer;
}

.just4FunSite .header .menu-toggle-icon {
    width: 26px;
    position: absolute;
    top: 1rem;
    left: 1rem;
}
.just4FunSite .header img {
    display: inline-block;

}

.cheeseburgerMenu{
    position: fixed;
    background-color: #D9DD50;
    text-align: center;
}


.cheeseburgerMenu .logo {
    /*margin:0 auto;
    margin-bottom: 2.5rem;*/
    position: absolute;
    top:10px;

}

.cheeseburgerMenu-inner {
    width:80%;
}

.menu {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
}

.menu-item {
    cursor: pointer;
    padding: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color:#000;
    border-color: #fff;
    border-bottom-width: 2px;
    font-size: 26px;
}

.menu-item.current {
    color: #2A3474;
    
}

.menu-item:hover{
    color: #2A3474;
}

.menu-item:last-child {
    border-color: #fff;
    border-bottom-width: 0px;

}


.ps__rail-y, .ps__rail-x{
    z-index: 5;
}


.about-img-just4Fun {
    flex-basis:40%; 
    align-self: center;
}

.about-img-just4Fun img {
    width:100%;
}

.about-txt-just4Fun {
    flex-basis:60%;
    align-self: center;
    text-align: center;
    padding: 30px 0;
}

.about-txt-just4Fun h2 {
    margin-bottom: 3%;
    font-weight: bold;
    font-size: 30px;
}


.about-container .divider {
    width: 60px;
    height: 5px;
    background-color: #D9DD50;
    margin: 0 auto;
    margin-bottom: 7%;
    margin-top: 4%;
}

.about-txt-just4Fun p {
    
    width: 90%;
    margin: 0 auto;
}

.home-banner-just4Fun img{
    width: 100%;
}

.sectionMerch {
    background-color: #2A3474;
    color:#fff;
    flex-direction: column;  
}


.sectionMerch .about-img-just4Fun {
    background-color: #E4F5FE;
    padding: 50px;
}



.sectionAbout, .sectionForum{
    flex-direction: column-reverse;
}


.signinJust4Fun .login-box {
    margin: auto;
    background-color: #2A3474;
    width: 100%;
    height: 100%;
    max-width: 100%;
    font-weight: 200;
}

.signinJust4Fun .login-box .annoucement {
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
}

.signinJust4Fun .error {
    font-weight: 200;
    font-size: 16px;
}

.signinJust4Fun .login-btn{
    background-color: #559FC9;
    font-size: 30px;
    margin: 0 auto;
    font-weight: 200;

}

.login-box .forgotPassword{
    text-decoration: none;
    text-align: center;
    display: block;
    color: #DCDCDC;
    font-size: 20px;
}



.profile-box {
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;

}

.profileInfosJust4Fun{
    flex-basis: 36%;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 10px;

}

.profileInfosJust4Fun h3, .profileSettingsJust4Fun h3{
    font-size: 26px;
}

 .profileSettingsJust4Fun p{
    font-size: 20px;
 }

.profileSettingsJust4Fun {
    background-color: #C43B34;
    flex-basis: 64%;
}

.sectionAbout1{
    flex-direction: column;
}
.sectionAbout2{
    flex-direction: column-reverse;
}

.about-container .about-txt-just4Fun,.about-container  .about-img-just4Fun {
    flex-basis: 50%;
}

.about-txt-just4Fun {
    width: 100%;
}

.merch-container, .newsletterSuccessMsg{
    margin: auto;
    font-size: 20px;
}

.products-container >div{
    flex-basis: 30%;
    cursor: pointer;
    margin-bottom: 40px;
}

.products-container img {
    margin: 0 auto;
}

.products-container {
    color: #C43B34; 
    flex-direction: column;
    margin: 0 10%;
    justify-content: space-between;
}

.forum-container{
    margin: 0 auto;
    margin-top: 10px;
    max-width: 800px;
}

.forum-container h2 {
    color:#2A3474;
    font-weight: bold;
    margin: 0 20px 5px 20px;
    font-size: 30px;
    margin-top: 20px;
}

.thread-reply-container {

    background:rgba(49,47,89,0.07);
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}


.thread-content {
    margin-bottom: 20px;
}
.thread-header {
    background-color: #E7F5F8;
    padding: 10px 20px;
    cursor: pointer;
}


.thread-username {
    padding-top: 10px;
}
.thread-header p:not(.thread-username) {
    margin-bottom: 20px;
}

.thread-header h3 {
    color: #AFAFAF;
    font-weight: bold;
    font-size: 32px;
    margin-top: 10px;
    line-height: 36px;
}

.thread-header h3.thread-title-margin{
    margin-bottom: 80px;
}


.forum-container .divider {
    width:100%;
    height: 1px;
    background-color:  #AFAFAF;
}


.thread-reply {
    
    margin: 0 20px;
    margin-right: 10px;
    
    margin-top: 20px;
}



.border-left {
    border-left: 2px solid #979797;
    padding-left: 20px;
}

.reply-btn {
    text-align: right;
    color: #797979;
    cursor: not-allowed;
}

.thread-comment{
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.thread-comment input {
    width: 100%;
    background: #D8D8D8;
    border-radius: 9px;
    padding: 10px 5px;
    font-weight: bold;
    margin-top: 20px;
}

.reply-username{
    color: #1D1934;
    padding-bottom: 10px;
    border-bottom: 2px solid #979797;
    margin-bottom: 7px;
}

.reply-content {
    color: #1D1934;
    margin-bottom: 25px;
}


.forum-image-container {
    margin: 30px 0;
}

.forum-image-container img {
    margin-bottom: 10px;
    max-width: 100%;
}


.clear {
    clear: both;
}


.inboxcontainer{
    background-color: #fff;
}

.inboxcontainer h3{
    margin-left: 28px; 
    font-size: 30px;
    text-transform: capitalize;
    border-bottom-width: 1px;
    padding: 5px 0;
}


.hamburgermenu {
    width: 30px;
    height: 20px;
    position: absolute;
    display: inline-block;
    border-top: 4px solid white;
    left: 10px;
    top: 14px;
}
.hamburgermenu:after, .hamburgermenu:before {
    content: '';
    width: 100%;
    height:4px;
    background-color:white;
    position:absolute;
}
.hamburgermenu:after {
    bottom:0px;
    left:0;
}
.hamburgermenu:before {
    top:4px;
    left:0;
}

.cheeseburgerMenu{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.cheeseburgerMenu .header {
    width:100%;
}



.cheeseburgerMenu .newmessage, .menuDesktop .newmessage,.newEmailBox .newmessage{
    background-color: #454545;
    color:white;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.menuDesktop .newmessage{
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
}

.newEmailBox .newmessage {
    margin-left: 0;
    margin-right: 0;
    width: 100px;
}


button:disabled {
    opacity: 0.6;
}

.menuContent {
    width: 100%;
}

.menuList ul {
    margin-top: 2rem;
}

.menuList li {
    padding: 10px 0px;
    border-bottom: 1px solid grey;
    margin: 0 20px;
    cursor: pointer;

}


.menuDesktop {
    width: 30%;
    border-right: 1px solid grey;
    margin: 10px 0;
}

.newEmailBox{
    background-color: #B3B3B3;
    width: 100%;
    margin-top:15px;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.newEmailBox textarea {
    width: 100%;
    flex: 1 1;
    font-size: 12px;
}


.inputContainer label {
    width: 70px;
    display: inline-block;
}


.inputContainer {
    margin-bottom: 1rem;
    font-size: 12px;
}

.overlay {
    position: fixed;
    top: 50px;
    left: 0;
    background: rgba(255,255,255,.8);
}

.email-item{
    position: relative;
}

.email-item .unread {
    background-color: #147EFB;
    width:10px;
    height:10px;
    position:absolute;
    border-radius:5px;
    top:15px;
    left:6.5px;
}

.email-item-content {
    border-bottom-width: 1px;
    border-bottom-color: #DFDDDE;
    padding-top:10px;
    padding-bottom:10px;
    display: flex;
    flex-direction:row;
    align-items:center;
    margin-left:25px;
}

.email-item-content .infos{
    flex:1;
}

.email-item-content .from {
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
}

.email-item-content .subject{
    font-size:12px;
}

.email-item-content .details{
    flex-direction: row; 
    align-items: center;
    margin-right: 20px;
    color:#147EFB;
    font-weight: bold;
}

.back-arrow{
    position: absolute;
    left: 10px;
    font-size: 30px;
}

.emailViewer{
    padding-left: 20px;
    padding-right: 20px;
}

.emailViewer .contentEmail{
    height: 10px;
    background-color: #fff;
}

.emailViewer .emailHeader {
    padding-top:15px;
    padding-bottom: 15px;
    border-bottom-color:#fff;
    border-bottom-width: 1px;
    flex-direction:row;
    align-items: center;
    display: flex;
    margin-bottom:15px;
}

.emailViewer .initials {
    width:40px;
    height:40px;
    background-color:#fff;
    border-radius:50px;
    margin-right:10px;
}

.emailViewer .textInitials {
    line-height:40px;
    font-size:16px;
    text-align:center;
    color:#000;
    font-weight:bold;
}
.emailViewer .subject {
    font-weight:bold;
    font-size:18px;
    padding:10px;
    background-color: #fff;
}


.emailViewer .rtfdoc {
    padding-top: 0px;
}

.bold {
    font-weight: bold;
}

.flexInbox{
    height: 20px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover{
    opacity: 0.7;
}

.forum-audio-player{
    margin:10px 0;
    width: 100%;
}

.forum-audio-player audio {
    max-width: 100%;
}

.overlaypw {
    /*background: rgba(0, 0, 0, 0.2);*/
    background-color: #C2D8D3;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000000000;
}
.audioViewer, .videoViewer{
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #6D6C6C;
    /*background-color: #D8D5AE;*/
    flex-direction: column;
}
.videoViewer > div {
    max-width: 100%;
    
}

.videoViewer {
    align-items: flex-start;
}
.audioViewerContent{
    width: 100%;
}

.player-item {
    margin: 20px ;
}

.audio-controls{
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    color:#fff;
}

.audio-controls button {
    margin: 0 10px;
}


    .HAKEmailChatContainer {
        overflow-y: scroll;
    }


iframe, .video{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px; 
}


.ReactModalPortal .ReactModal__Overlay{
    z-index: 100000000000 !important;

}


/*.print-icon {
    position: absolute;
    padding: 0px 10px;
    left: 0;
    top: 0;
}*/


.top-bar-menu {
    background-color: #3BBFAD;
    color:#1D1934;
    text-align: center;
    height: 45px;
    text-transform: uppercase;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    
}



.top-bar-menu h1, .mobile-menu h1{
    margin:0;
    padding: 0;
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.flex-1.full-height {
    max-height: calc(100% - 45px);
}

.blair-witch-content {
    max-height: calc(100% - 80px);
    overflow: scroll;
    flex-direction: column;
    padding: 0 20px;
}

.blogcontent {
    background-color:rgba(49,47,89, 0.4);
    padding:40px 20px;
    flex:1;
    color: #fff;
    position: relative;
}




.desktopicons {
    width: 120px;
    display: none;
}


.blair-witch-dekstop {
    background: #000;
    background-image: url('https://cdn.huntakiller.com/blair-witch/s2/bwp0201-superneutral-background-desktop.png');
    background-size: 100%;
    background-repeat: no-repeat;
    /*background-position-y: 45px;*/

    margin-top: 45px;
    height: calc(100% - 45px);
    overflow: auto;

}

.user_profile {
    
    padding:20px;
    position: relative;
    text-align: right;
    color:#605F89;

}

.user_profile h2 {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 15px;
}

.user_profile p {
    margin-bottom: 50px;
}

.profile_picture{
    border-radius: 50%;
    width: 200px;
    margin:0 auto;
    margin-bottom: 30px;
}


.tipline-btn {
    margin-bottom: 20px;
    cursor: pointer;
}

.tipline-btn:hover  {
    opacity: 0.8;
}

button:focus {outline:0;}

.tipline-btn > div {
    position: relative;
    background: #E04403;
    color: #1D1934;
    display: inline-block;
    padding: 15px 20px;
    font-weight: bold;
}

.tipline-btn > div:after{
    content: " ";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #E04403;
    position: absolute;
    bottom: -20px;
    left: 16px;
}

.mobile-menu-icon {
    position: fixed;
    top: 3px;
    left: 20px;
}
.mobile-menu-icon > div {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
}


.mobile-menu {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #3BBFAD;
    color: #1D1934;
    
    text-align: center;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
    overflow-y:auto;
}

.mobile-menu > div {
    background-color: #3BBFAD;
    margin: 27px 20px;
}

.mobile-menu li div.divider {
    
    height: 3px;
    background: white;
    margin: 0 auto;
    
}
.mobile-menu ul {
    margin-top: 2em;
}

.mobile-menu li {
    font-size: 1.5em;
    /*line-height: 4em;*/
    position: relative;
    cursor: pointer;
}

.mobile-menu li.current, .mobile-menu li:hover{
   color: #E04403; 
}


.mobile-menu li > div:not(.divider) {
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 30px;
}

/*.mobile-menu li > div >span , .mobile-menu li .exclamation-bubble {
    float: left;
}*/

.mobile-menu li > div >span {
    position: relative;
}

 .mobile-menu li .exclamation-bubble {
    position: absolute;
    top: -42px;
    width: 91px;
    right: -65px;
    min-width: 91px;
}

.mobile-menu .close {
    height: 50px;
    width: 50px;
    position: fixed;
    background-image: url(https://cdn.huntakiller.com/blair-witch/s1/close.svg);
    background-position: center;
    background-size: 100%;
    right: 0px;
    top: 0px;
}

.content-file .icon-wrapper{
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
}

.blog_entry .blog_title {
    border-bottom: 2px solid #979797;
    
    margin: 0 20px;
    margin-bottom: 13px;
}

.blog_entry .blog_title h2{
    font-size: 1.2em;
    font-weight: bold;
    color: #E04403;
    margin-bottom: 15px;
}

.blog_entry .blog_title h3 {
    color: #1D1934;
    font-size: 1em;
    margin-bottom: 10px;
    font-weight: bold;
}

.blog_entry .blog_text p {
    font-size: 12px;
    color: #1D1934;
}

.blog_entry .blog_text {
    padding: 0 20px;
    margin-bottom: 20px;
}

.blog_entry {
    padding:20px 0;
    padding-bottom: 0;
    border: 2px solid #979797;
    background: #D8D8D8;
    margin-bottom: 25px;
}

.blog_nav {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1em;
}

.pastBlogEntryBtn {
    cursor: pointer;
    display: flex;
    align-items: center;

}

.newBlogEntryBtn {
    cursor: pointer;
    align-self: flex-end;
    display: flex;
    align-items: center;
}



 .glitch-right .glitch, .glitch-right-1 .glitch, .glitch-right-2 .glitch, .glitch-right-3 .glitch {
  animation-delay: 0.5s;
  animation-duration: 4s;
  animation-name :glitch-skeww;
}

.glitch-right-1 .glitch, .glitch-password-4 .glitch {
    animation-delay: 0.1s;
}

.glitch-right-2 .glitch, .glitch-password-3 .glitch  {
    animation-delay: 0.2s;
    animation-duration: 3.5s;
}

.glitch-right-3 .glitch, .glitch-password-2 .glitch {
    animation-delay: 0.3s;
    animation-duration: 3s;
}


.glitch-right-3 .glitch, .glitch-password-5 .glitch {
    animation-delay: 0.3s;
    animation-duration: 6s;
}

.glitch-right-3 .glitch, .glitch-password-6 .glitch {
    animation-delay: 0;
    animation-duration: 5s;
}





.glitch-right, .glitch-right-1, .glitch-right-2, .glitch-right-3, .glitch-password , .glitch-password-2, .glitch-password-3, .glitch-password-4 {
    position: fixed;
    left: 0;
    top:35%;
    height: 50px;
    width: 40%;
}

.glitch-password-5, .glitch-password-6{
    position: absolute;
    height: 50px;
    width: 100%;
    left: 0;
    top: 0;
}

.glitch-right-1{
    top: 45%;
    width: 30%;
    left: -10%;
    height: 20px;
}

.glitch-right-2{
    top: 69%;
    width: 60%;
    height: 30px;
}

.glitch-right-3{
    top: 90%;
    width: 55%;
    left: -5%;
    height: 30px;
}

 .glitch-password {
    top:10%;
 }

 .glitch-password-2 {
    left: auto;
    right: 0;
    top: auto;
    bottom: 5%;
 }


.glitch-password-3 {
    transform: rotate(90deg);
    top: 0;
    right: -5%;
    left: auto;
}

.glitch-password-4 {
    top:80%;
 }


.wierd-glitch-2-right{
    width: 100%;
    height: 50px;
    background-image: url('https://cdn.huntakiller.com/blair-witch/s1/weird-glitch-2.png');
}



.glitch-right-1 .wierd-glitch-2-right{
    height: 20px;
}

.glitch-right-2 .wierd-glitch-2-right{
    height: 30px;
}

.glitch-right-3 .wierd-glitch-2-right{
    height: 30px;
}


 .glitch-password-2 .wierd-glitch-2-right{
    height: 100px;
}

 .glitch-password-3 .wierd-glitch-2-right{
    height: 100px;
}

.glitch-password-5 .wierd-glitch-2-right{
    height: 400px;
}

.glitch-password-6 .wierd-glitch-2-right{
    height: 100vh;
}

.forgot-password {
    cursor: pointer;
}

.forgot-password:hover{
    color: red;
}


audio {
    outline:none;
}

.site-logo {
    padding: 20px;
    padding-bottom: 0;
    margin-top: 45px;
}

.home-divider {
    display: block;
    height: 3px;
    background-color: #3BBFAD;    
    margin:20px 0;    
}

.homeSection a {
    color: #E04403;
}

.homeSection a:hover{
    opacity: 0.9;
}

.homeSection h2{
    color: #E04403;
    font-family: 'CCMonsterMash';
    font-size: 1.5em;
    text-align: center;
}

.homeSection h1 {
    color: #549F92;
    font-family: 'CCMonsterMash';
    font-size: 2em;
    text-align: center;
}

.homescrollable-container {
    border: 1px solid #979797;
    background-image: url('https://cdn.huntakiller.com/blair-witch/s2/bwp0201-superneutral-background-desktop.png');
    background-size: cover;
    height: 560px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding:20px;
    margin-top: 20px;

    width: calc(100% + 40px);
    margin-left: -20px;

}

.homescrollable-copy-header {
    font-family: 'CCMonsterMash';
    flex-shrink: 0;
    width: 35%;
    margin-right: 20px;
    

}


.homescrollable-content {
    font-size: 0.8em;
}

.homescrollable-content > .flex {
    margin-bottom: 40px;
}

.red {
    color: #E04403;
}

.blue {
    color: #3BBFAD;
}

.aboutSection h1 {
    color: #E04403;
    font-family: 'CCMonsterMash';
    font-size: 1.6em;
    line-height: 1.2em;
    text-align: center;
}


.aboutSection p,.blogPostFromReconTeamContent-left p {
    font-size: 0.9rem;
    

}

.aboutSection > h1 {
    margin-bottom: 35px;
}


.aboutSection > p {
    border-bottom: 4px solid #3BBFAD;
    padding-bottom: 45px;
    margin-bottom: 36px;
}

.aboutSection .flex {
    flex-direction: column;
    align-items: flex-start;
}

.aboutSection .flex > div {
    flex:1;
}

.aboutSection .flex img {
    margin:0 auto;
    margin-top: 10px;
    margin-bottom: 26px;
}

.aboutSection .flex > div:nth-child(1) {
    padding-bottom: 48px;
    border-bottom: 4px solid #3BBFAD;
    margin-bottom: 50px;
}


.past-investigations-section .flex {
    flex-direction: column;
}

.past-investigations-section .flex > div{
    margin-bottom: 25px;
    cursor: pointer;
}

.past-investigations-section h1,.past-investigation-section h1 {
    color:#E04403;
    font-family: 'CCMonsterMash';
    font-size: 1.7em;
    line-height: 1em;
    text-align: center;
    margin-top: 23px;

}

.past-investigation-section h1 {
    margin-bottom: 23px;

}

.past-investigation-section h2 {
    color: #E04403;
    font-size: 2em;
    margin-bottom: 60px;
    font-family: 'CCMonsterMash';
    text-align: center;
    line-height: 1em;
}

.past-investigation-section h3 {
    color: #E04403;
    font-size: 2.9em;
    font-family: 'CCMonsterMash';
    text-align: center;

}

.past-investigation-section h3.blue {
    color: #3BBFAD;
}

.past-investigation-section p {
    margin-bottom: 54px;
    font-size: 0.9rem;

}

.past-investigations-section {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -40px;
}

.past-investigation-section img {
    width: 100%;
    cursor: pointer;
}

.past-investigation-section p.editornote {
    margin-bottom: 0;
    margin-top: 40px;
}

.past-investigation-section img.past-investigation-close-button {
    position: absolute;
    width: 25px;
    top: 0;
    right: 0;
    background-color: #3BBFAD;
    padding:5px;
}

.blogPostFromReconTeamContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: #C3CBDD;
}

.electrical_interface_modal {
    z-index: 11;
}


.blogPostFromReconTeamContent {
    width: 1330px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}

.blogPostFromReconTeamContent-left {
    background-color: #fff;
    flex:1;
    padding:30px 20px;
}

.blogPostFromReconTeamContent-left  h1 {
    font-size: 1.9em;
    line-height: 1.3em;
    padding-bottom: 23px;
    border-bottom: 2px solid #979797;
    margin-bottom: 12px;
}

.blogPostFromReconTeamContent-left  h2 {
    color: #868686;
    font-size: 1.1em;
    margin-bottom: 30px;
}

.blogPostFromReconTeamContent-right img {
    margin-bottom: 30px;
    cursor: pointer;
}

 .blogPostFromReconTeamContent-right {
    padding-top: 46px

 }
.blogPostFromReconTeamContent-right h3 {
    margin-bottom: 17px;
    font-size: 2.4em;
    
    color: #fff;
    text-align: center;
}

.blogPostFromReconTeamContent-left .videoContainer {
    margin:17px 0;
}



@media only screen and (max-width: 800px) {
    .modal.react-draggable {
        touch-action: auto !important;
    }

    input, select { font-size: 16px !important; }

}
@media only screen and (max-width: 400px) {
.rtfdoc.lyrics{
    font-size: 10px;
}
}

@media screen and (min-width:1025px){
    
   
}

@media only screen and (min-width: 800px) {
    /*.desktopicons-content {
        position: fixed;
    }*/
    .blogPostFromReconTeamContent {
        flex-direction: row;
        margin:0 auto;
    }

    .blogPostFromReconTeamContent-right {
        padding: 0 30px;
        padding-top: 83px;
        flex: 0 0 438px;
    }
    .blogPostFromReconTeamContent-right h3 {
        margin-bottom: 23px;
        
       
    }

    .desktopicons {
        padding-top: 50px;
        display: block;
        padding-right: 20px;
    }

    .blair-witch-content{
        flex-direction: row-reverse;
        max-width: 1300px;
        margin: 0 auto;
        overflow: hidden;
    }

    
    
    .tipline-btn-container {
        position: fixed;
        bottom: 10px;
    }
    .user_profile {
        min-width: 112px;
        padding: 2%;

        
    }


    .blogPostFromReconTeamContent-right img {
        margin-bottom: 45px;
    }

    .blogPostFromReconTeamContent-right img:hover{
        opacity: 0.8;
    }

    .blogPostFromReconTeamContent-left {
        padding:20px 40px;
    }

    .blogPostFromReconTeamContent-left  h1 {
        font-size: 3em;
        margin-bottom: 20px;

    }
    .blogPostFromReconTeamContent-left  h2 {
        font-size: 1.7em;
        margin-bottom: 36px;
    }

    .blogPostFromReconTeamContent-left .video {
        margin:30 0px;
    }

    .blogPostFromReconTeamContent-left .videoContainer {
        width: 320px;
    }

    .past-investigation-section img.past-investigation-close-button {
        width: 35px;
        padding:10px;

    }

    .past-investigation-section img.past-investigation-close-button:hover {
        opacity: 0.8;
    }
    .past-investigations-section {
        width: 100%;
        margin: 0;
    }


    .past-investigations-section .flex {
        flex-direction: row;
        justify-content: space-around;
    }

    .past-investigations-section .flex > div{
        width: 45%;
        margin-bottom: 80px;
    }

    .past-investigations-section .flex > div:hover{
        opacity: 0.8;
    }

    .past-investigation-section {
        padding: 50px 70px;
    }

    .past-investigation-section p {
        margin-bottom: 114px;
        font-size: 1.1rem;
    }

     .past-investigation-section p.editornote {

        margin-top: 47px;
        text-align: center;
     }

    .past-investigations-section h1,.past-investigation-section h1  {
        font-size: 4em;
        line-height: 0.8em;
        margin-top: 33px;

    }

    .past-investigation-section h1  {
        margin-bottom: 33px;
    }

    .past-investigation-section h2 {

        margin-bottom: 78px;
        font-size: 4em;
    }

    .blog_nav {
        font-size: 1.3em;
    }

        .thread-reply-container {
        padding-left: 110px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1em;
    }

    .reply-username{
        padding-bottom: 25px;
        border-bottom: 3px solid #979797;
        margin-bottom: 19px;
    }

    .reply-content{
        margin-bottom: 50px;
    }


    .homescrollable-content {
        font-size: 1.1em;
    }
    .homescrollable-container {

        padding-top: 50px;
        padding-left: 50px;
        padding-right: 30px;
        margin-top: 40px;
        width: 100%;
        margin-left: 0px;
    }

    .homeSection h2{
    
        font-size: 2.5em;
    }

    .homeSection h1 {
        font-size: 3em;
    }

    .aboutSection h1 {
        font-size: 4em;
        line-height: 1.3em;
       
    }

    .aboutSection > h1 {
         margin-bottom: 55px;
    }

    .aboutSection p ,.blogPostFromReconTeamContent-left p{
        font-size: 1.1rem;
    }

     .aboutSection > p {
        padding-bottom: 75px;
        margin-bottom: 100px;
    }



    .aboutSection .flex {
        flex-direction: row;
    }

    .aboutSection .flex > div:nth-child(1) {
        margin-right: 60px;
        border:none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .aboutSection .flex > div:nth-child(2) {
        margin-left: 60px;
    }

    .aboutSection .flex > div:nth-child(2) h1{
        color: #3BBFAD;
    }
    


    .aboutSection .flex img {
        margin-top: 25px;
        width: 400px;
        margin-bottom: 46px;
    }

    .homescrollable-container::-webkit-scrollbar {
      -webkit-appearance: none;

      background-color: rgba(255,255,255,.5);
      width: 10px;
    }

    .homescrollable-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }


    .site-logo {
        padding-top: 60px;
        max-width: 900px;
        margin: 0 auto;
        margin-top: 50px;
        padding-bottom: 11vh;
    }


    .top-bar-menu .menu.desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        line-height: 70px;
        cursor: pointer;
        font-size: 1.5em;
        font-weight: bold;
        padding: 0;

    }

    .top-bar-menu {height: 70px;}

    .top-bar-menu .menu.desktop div:hover, .top-bar-menu .menu.desktop div.current {
        color: #E04403;
    }

     .top-bar-menu .menu.desktop div.current {
        cursor: default;
     }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    .blog_entry .blog_title h2{
        font-size: 2.4em;
    line-height: 1.3em;
    margin-bottom: 40px;

    }

    .blog_entry .blog_title {
    border-bottom: 3px solid #979797;
     margin: 0 50px;
    margin-bottom: 27px;
   
}

    .blog_entry .blog_title h3 {
    font-size: 1.2em;
    margin-bottom: 30px;
}


    .blog_entry {
        padding:50px 0;
        padding-bottom: 0;
        margin-bottom: 45px;
    }

    .blog_entry .blog_text p {
        font-size: 1.1em;
    }

    .blog_entry .blog_text {
        padding: 0 50px;
        margin-bottom: 50px;
    }

    .blog_entry img {
        /*width: 400px;*/
        max-width: 100%;
        cursor: pointer;
    }

    .mobile-menu-icon {
        display: none;
    }


    .tipline-btn {
        position: relative;
        right: auto;
        bottom: auto;
        margin-bottom: 43px;
    }



    .user_profile h2 {
        font-size: 2em;
    }

    .profile_picture {
        width: 230px;
        max-width: 90%;
    }
    .blogcontent {
        padding:80px 50px;
        
    }




    .top-bar-menu h1{
        font-size: 2.5em;

    }
    .modalcontainer{
        moz-box-shadow:  1px 1px 2px 1px #868686;
       -webkit-box-shadow: 1px 1px 2px 1px #868686;
        box-shadow: 1px 1px 2px 1px #868686;
    }
    .products-container {
        align-items: baseline;
        margin: 0;
    }
    .about-txt-just4Fun {
        width: auto;
    }

    .profileInfosJust4Fun h3{
        height: 40px;
    }

    .profileSettingsJust4Fun{
        margin-top: 40px;
    }

    .profileInfosJust4Fun{
        margin:0;
        margin-right: 20px;
    }
    .signinJust4Fun .login-box {
        height: auto;
        max-width: 600px;
    }


    .signinJust4Fun .login-box .annoucement {

        margin-bottom: 2rem;
    }

    .signinJust4Fun .error {
        margin-top: 0;
    }
    .sectionAbout, .sectionForum, .sectionMerch, .profile-box, .sectionAbout1,.sectionAbout2, .products-container  {
        flex-direction: row;
    }
    .desktop {
        display: block;
    }
    .mobile, .just4FunSite .header .menu-toggle-icon {
        display: none;
    }

    .about-txt-just4Fun h2 {
        font-size: 46px;
    }

    .about-txt-just4Fun p {
        font-size: 24px;
        line-height: 26px;
        width: 70%;

    }


    .about-container .about-txt-just4Fun p {
        font-size: 20px;
        line-height: 22px;
    }


    .menu-item{
        display: inline-block;
        padding: 0;
        margin: 0.6rem 1rem; 
        margin-bottom: 0rem;
        color:#2A3474;
        border-color: transparent;
        border-bottom-width: 2px;

    }

    .menu-item.current {
        color: #2A3474;
        border-color: #2A3474;
        border-bottom-width: 2px;
    }

    .menu-item:hover {
        color: #2A3474;
        border-color: #2A3474;
        border-bottom-width: 2px;
    }



    .icon-wrapper{
        width: 90px;
        height: 128px;
    }
    .rtfdoc table{
        min-width:  100%;
    }
    /*.rtfdoc{
        width: 110%;
        padding-right: calc(20px + 10%);
    }*/

    .rtfdoc ul {
        padding-left: 15px;
        margin-left: 40px;
    }

    object{
        width:auto;
    }

    .bottom-imageViewer-controllers{
        display: block;
       position: fixed;
       height: 50px;
        
    }
     .bottom-imageViewer-controllers button{
        display: inline-block;
     }


  .App-clock{
    padding-right: 70px;
  }



  .modal {
       
        width: 800px;
        height: 525px;
        min-height: auto;

    }



    /*.folder-content{
        min-height: 600px;
        
    }*/

    .content-file{
        max-width: none;
        max-height: none;
        /*min-width: 110%;*/
    }

    .imageViewer{
        height: calc(100% - 100px);
    }
    .imageViewer .content-file, .imageViewer .rtfdoc{ 
        
        min-width: 100%;
    }


    .imageViewer img{
        width: 100%;
    }


    /*.ScrollbarsCustom-Content{
        min-width: 110% !important;
        min-height: 110% !important;
    }*/
    .flexInbox {
        display: flex;
        flex-direction: row;
    }

    .menuList li:hover{
        color:#454545;
    }

    .newEmailBox{
        margin:15px;
        border-radius: .5rem;
    }



    .forum-image-container img {
        margin-bottom: 10px;
        max-width: 100%;
        float: left;
        width: calc(25% - 7.5px);
        margin-right: 10px;
    }


    .forum-image-container img:nth-child(4n) {
        margin-right: 0;
    }




    iframe{
        width: 500px;
        height: 300px;

    }

    .thread-header{
        padding: 10px 40px;
    }

    .thread-reply{
        margin: 30px 20px 0 40px;
    }

    .border-left {
        padding-left: 40px;
    }

    .forum-container h2 {
        margin: 40px 0px 30px 0px;
    }

    .newest-thread-section h2 {
        margin-top: 20px;
    }

    .thread-header h3.thread-title-margin {
        margin-bottom: 20px;
    }

    
    .videoViewer > div {
        width: 100% !important;
        height: 100% !important;
    }

    .home-hero, .homeSection > p {
        padding: 0 20px;
    }


    .home-divider {
        margin:60px 0;
    }

    .blair-witch-dekstop {
        /*background-position-y: 70px;*/
        margin-top: 70px;
    height: calc(100% - 70px);
    /* flex: 1; */
    overflow: auto;
    }
}




@media only screen and (min-width: 800px) and (max-width: 1100px) {
    .past-investigations-section h1,.past-investigation-section h1, .aboutSection h1  {
        font-size: 3em;
       
    }

    .desktopicons {
        padding-top: 10px;
        
    }

    
    .blogcontent {
        max-width:75%;
    }
}


@keyframes glitch-skeww {

  9% {
    filter: hue-rotate(100deg);
  }
  10% {
    filter: hue-rotate(200deg);
  }
  11% {
    filter: hue-rotate(50deg);
    opacity: 1;
  }
  12% {
    opacity: 0.3;
    filter: hue-rotate(0deg);
  }
  60% {
    filter: grayscale(30%) hue-rotate(180deg);
  }
  61% {
    filter: hue-rotate(180deg);
  }
  62% {
    filter: grayscale(30%);
  }
  64% {
    filter: grayscale(30%);
  }
  
}